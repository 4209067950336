import axios from "axios"
// import { useDispatch } from "react-redux";

//pass new generated access token here
const { token } = JSON.parse(localStorage.getItem('authUser')) || {};

//apply base url for axios
export const API_URL = "https://wmsapi.aerisclinic.au/api/v1"
// const dispatch = useDispatch();

export const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = token && `Bearer ${token}`

axiosApi.interceptors.response.use(
  response => {
    if (!response.data.status) {
      if (response.data.message == 'Token is Expired' || response.data.message == 'Token is Invalid' || response.data.message == 'Authorization Token not found') {
        // dispatch(logoutUser(res, props.history));
        localStorage.removeItem('authUser');
        window.location.replace(window.location.origin + '/login');
        return response;
      }
      return response;
    }
    return response;
  },

  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return await axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return await axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function postFormData(url, data, config = {}) {
  return await axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}

export async function download(url, filename, config = { responseType: 'blob' }) {
  return await axiosApi
    .get(url, { ...config })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    })
}
