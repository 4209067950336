const { get, post, put, del, postFormData } = require("./api_helper")

const api = {
  getStripeKey(data) {
    return get("stripe-key", data)
  },
  getAuthenticatedUser(data) {
    return get("get-authenticated-user", data)
  },
  getQuestionaryQuestion(data) {
    return get("get-questionary", data)
  },
  checkin(data) {
    return post("checkin", data)
  },
  checkout(data) {
    return post("checkout", data)
  },
  getSelectEmployee(data) {
    return get(`select-employee`, data)
  },
  getRoles(data) {
    return get(`get-roles`, data)
  },
  getSelectGp() {
    return get("select-gp")
  },
  getStaff(data) {
    return get("staffs", data)
  },
  addStaff(data) {
    return post("staffs", data)
  },
  updateStaff(data) {
    return put(`staffs/${data.id}`, data)
  },
  getSelectDoctor(data) {
    return get("select-doctor", data)
  },
  getExternalDoctor(data) {
    return get("external-doctors", data)
  },
  getDoctor(data) {
    return get("doctors", data)
  },
  addDoctor(data) {
    return postFormData("doctors", data)
  },
  updateDoctor(data, id) {
    return postFormData(`doctors/${id}`, data)
  },
  scriptHistory(data) {
    return get("script-history", data)
  },
  getSelectPatient(data) {
    return get("select-patient", data)
  },
  getPatientCount(data) {
    return get("patient-count", data)
  },
  getPatient(data) {
    return get("patient", data)
  },
  getArchivePatient(data) {
    return get("archives-patient", data)
  },
  getExistPatient(data) {
    return get("exist-patient", data)
  },
  getNewPatient(data) {
    return get("new-patient", data)
  },
  getReferralPatient(data) {
    return get("referral-reminder", data)
  },
  getScriptPatient(data) {
    return get("script-reminder", data)
  },
  getPatientDetail(id) {
    return get(`patient/${id}`)
  },
  addPatient(data) {
    return postFormData("patient", data)
  },
  updatePatient(data, id) {
    return postFormData(`patient/${id}`, data)
  },
  flagPatient(data, id) {
    return post(`flag-patient/${id}`, data)
  },
  addDocument(data) {
    return postFormData(`add-document`, data)
  },
  removeDocument(data) {
    return post(`remove-document`, data)
  },
  sendQuestionary(data) {
    return post(`send-questionary`, data)
  },
  submitQuestionary(data) {
    return postFormData(`submit-questionary`, data)
  },
  verifyUser(data, id) {
    return post(`verify-user/${data.id}`, data)
  },
  updatePatientDetail(data) {
    return post(`patient-detail/${data?.user?.id}`, data)
  },
  getPatientType(data) {
    return get("patient-type", data)
  },
  getPatientProblem(data) {
    return get("patient-problem", data)
  },
  selectPatientProblem(data) {
    return get("select-patient-problem", data)
  },
  getPatientNote(data) {
    return get("patient-notes", data)
  },
  addPatientNote(data) {
    return post("patient-notes", data)
  },
  updatePatientNote(id, data) {
    return put(`patient-notes/${id}`, data)
  },

  getEvents(data) {
    return get("events", data)
  },
  addNewEvent(data) {
    return post("events", data)
  },
  updateEvent(data) {
    return put(`events/${data.id}`, data)
  },
  deleteEvent(id) {
    return del(`events/${id}`)
  },
  addNewEventCategory(data) {
    return post("event-categories", data)
  },
  getSetting() {
    return get(`settings`)
  },
  updateSetting(data) {
    return postFormData(`update-settings`, data)
  },
  getTodayAppointment(data) {
    return get(`today-appointment`, data)
  },
  getFinishAppointment(data) {
    return get(`finish-appointment`, data)
  },
  getInvoices(data) {
    return get(`invoice`, data)
  },
  checkAppointmentAvailability(data) {
    return get(`appointment-availability`, data)
  },
  getAppointment(data) {
    return get(`appointment`, data)
  },
  addAppointment(data) {
    return post("appointment", data)
  },
  updateAppointment(data) {
    return put(`appointment/${data.id}`, data)
  },
  removePayment(data) {
    return get(`remove-payment/${data.id}`)
  },
  getGeneralPractice(data) {
    return get("general-practices", data)
  },
  addGeneralPractice(data) {
    return post("general-practices", data)
  },
  updateGeneralPractice(data) {
    return put(`general-practices/${data.id}`, data)
  },
  getAllPharmacy(data) {
    return get("get-pharmacy", data)
  },
  getPharmacy(data) {
    return get("pharmacies", data)
  },
  addPharmacy(data) {
    return postFormData("pharmacies", data)
  },
  updatePharmacy(id, data) {
    return postFormData(`pharmacies/${id}`, data)
  },
  selectPharmacy(data) {
    return get("select-pharmacy", data)
  },
  getBrand(data) {
    return get("brands", data)
  },
  addBrand(data) {
    return post("brands", data)
  },
  updateBrand(id, data) {
    return put(`brands/${id}`, data)
  },
  selectBrand(data) {
    return get("select-brand", data)
  },
  getSpecialist(data) {
    return get("specialists", data)
  },
  addSpecialist(data) {
    return post("specialists", data)
  },
  updateSpecialist(id, data) {
    return put(`specialists/${id}`, data)
  },
  selectSpecialist(data) {
    return get("select-specialist", data)
  },
  getExternalDoctor(data) {
    return get("external-doctor", data)
  },
  addExternalDoctor(data) {
    return post("external-doctor", data)
  },
  updateExternalDoctor(id, data) {
    return put(`external-doctor/${id}`, data)
  },
  selectExternalDoctor(data) {
    return get("select-external-doctor", data)
  },
  getQuestionary(data) {
    return get("questionaries", data)
  },
  addQuestionary(data) {
    return post("questionaries", data)
  },
  updateQuestionary(data) {
    return put(`questionaries/${data.id}`, data)
  },
  getReportDoctor(data) {
    return get("data-report-doctor", data)
  },
  checkCoupon(data) {
    return post("check-coupon", data)
  },
}

export default api;